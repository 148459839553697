import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';

function App() {
	return (
	<Router>
		<nav class="navbar navbar-expand-lg bg-body-tertiary">
			<div class="container">
				<a class="navbar-brand" href="/">Aplikasi</a>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarText">
					<ul class="navbar-nav me-auto mb-2 mb-lg-0">
						<li class="nav-item">
							<a class="nav-link" aria-current="page" href="/">Home</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" aria-current="page" href="/contact">Contact</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" aria-current="page" href="/about">About</a>
						</li>
					</ul>
					<span class="navbar-text">
						Aplikasi ReactJS pake BS
					</span>
				</div>
			</div>
		</nav>
		<div className="container-fluid">
			<div className="container py-3">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/contact" element={<Contact />} />
				</Routes>
			</div>
		</div>
	</Router>
	);
}

export default App;